import React, { Component } from 'react'
import { connectStoreon } from 'storeon/react';
import WithRouter from '../../../HOC/WithRouter';
import CarSell from './CarSell';
import { ROOT } from '../../../helpers/config';
import { SET_DATA_CAR_SALE } from '../../../store/car-sale/carSale';
import { ACTION_SET_CONTROLL_BUTTON } from '../../../store/helpers/helpers-store';
import { delay } from '../../../helpers/const';

class CarSellComponent extends Component {

  componentDidMount() {
    this.props.controllerHeaderBand({
      currentTextHandlerBand: 'Разместить авто на продажу',
      pathBackButton: () => this.handlerChangeScreen({ path: ROOT })
    });

    
    // const delayF = async () => {
    //   await delay(2000);
    //   console.log(this);
    //   this.props.dispatch(ACTION_SET_CONTROLL_BUTTON, {
    //     isActive: true,
    //     isFetch: true,
    //     name: "Создать",
    //     action: () => {},
    //   });
    // };
    // delayF()
  }
  handlerChangeScreen = ({ path }) => {
    this.props.navigate(path)
  }
  
  handlerChangeDataValues = values => this.props.dispatch(SET_DATA_CAR_SALE, { ...values });

  render() {
    return (
      <CarSell
      dispatch={this.props.dispatch}
      handlerChangeDataValues={this.handlerChangeDataValues}
    /> 
    )
  }
}

export default connectStoreon( 
  WithRouter(CarSellComponent)
);